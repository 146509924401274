#root {
  height: 100%;
}

.ant-layout {
  height: 100%;
  background-color: #fff;
}

.ant-layout-header {
  padding: 0;
}

.ant-layout-sider {
  height: 100%;
}

.ant-layout tr {
  cursor: pointer;
}

.ant-table-title {
  cursor: pointer;
  padding: 0;
  margin: 0;
  /* background-color: lightblue; */
}

.notion-text {
  font-size: 15px;
  font-weight: bold;
  text-decoration: underline;
}
.notion-list-disc li {
  font-size: 13px;
  padding: 2px 0px;
  /* background-color: red; */
}
.notion-asset-wrapper {
  padding: 0;
  margin: 0;
}
.ant-radio-button-wrapper {
  background-color: unset;
}
.ant-typography pre {
  font-size: 11px;
}
